import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Article } from '../Models/article';
import { Glossary } from '../Models/glossary';
import { Links } from '../Models/links';
import { CategoryData } from './categoryName';
import { TranslateService } from '@ngx-translate/core';
import { Category } from "../Models/category";
import { CommonService } from '../Services/common.service';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator, MatSnackBar, MatDialog } from '@angular/material';
import { ArticleEditComponent } from './articleEdit.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';




declare var $: any

@Component({
  selector: 'app-home-page',
  templateUrl: './homePage.component.html',
})
export class HomePageComponent {
  model = new Article();
  model1 = new Glossary();
  model2 = new Links();
  categoryList: any[];
  linksList: any[];
  publishedStatus1: boolean = false;
  publishedStatus2: boolean = false;
  publishedStatus3: boolean = false;
  errorMessage: string = "";
  userHash: string;
  uploadedFiles: Array<File>;
  verifiedBlobList: any;
  finalList: Article[] = [];
  imageFile: string;

  ////////////////////////////////////Text Editor//////////////////////////////////
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '5rem',
    maxHeight: '15rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    sanitize: false,
    toolbarPosition: 'top',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };
//////////////////////////////////////////////////////////////////////////////////////////////////////
  constructor(private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private commonService: CommonService,
    private sbar: MatSnackBar,
    private translate: TranslateService, ) { }

  ngOnInit() {
    this.getCategoryList()
    this.getLinksList()
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment && document.getElementById(fragment) != null) {
        document.getElementById(fragment).scrollIntoView({ behavior: "smooth" });
      }
    });
    this.getArticleList();
  }

  article() {
    let user = sessionStorage.getItem("currentUser") ? JSON.parse(sessionStorage.getItem("currentUser")) : {};
    let request = [{
      userHash: user ? user.UserHash : "",
      //articleImageName: this.uploadedFiles[0].name === undefined ? '' : this.uploadedFiles[0].name,
      articleImageName : this.imageFile,
      authorName: user ? user.FirstName + ' ' + user.LastName : "",
      publishedDate: new Date().toDateString(),
      articleCategory: this.model.articleCategory,
      articleTitle: this.model.articleTitle,
      articleText: this.model.articleText,
      publishedStatus: this.model.publishedStatus1 ? "Yes" : "No"
    }]
    let url: string = "/web/register_new_article/recordNewArticle";
    this.commonService.postrequestwithouttoken(url, request)
      .subscribe(() => {
        this.sbar.open("Successfuly Saved Article")
      }, () => {
        //error from api 
        this.errorMessage =
          this.translate.instant("Error")
      });
      this.model = new Article();
      
  }

  glossary(glossaryForm: NgForm) {
    let user = sessionStorage.getItem("currentUser") ? JSON.parse(sessionStorage.getItem("currentUser")) : {};

    let request = [{
      userHash: user ? user.UserHash : "",
      category: glossaryForm.form.value.category,
      glossaryTerm: glossaryForm.form.value.glossaryTerm,
      glossaryDesc: glossaryForm.form.value.glossaryDesc,
      authorName: user ? user.FirstName + ' ' + user.LastName : "",
      publishedDate: new Date().toDateString(),
      publishedStatus: glossaryForm.form.value.publishedStatus2 ? "Yes" : "No",
      glossaryAcronym: glossaryForm.form.value.glossaryAcronym
    }]

    let url: string = "/web/register_new_glossary/recordNewGlossary";
    this.commonService.postrequestwithouttoken(url, request)
      .subscribe(() => {
        this.sbar.open("Successfuly Saved Glossary")
      }, () => {
        //error from api 
        this.sbar.open("Error in saving Glossary")
      });
      glossaryForm.reset();
  }

  links(linksForm: NgForm) {
    let user = sessionStorage.getItem("currentUser") ? JSON.parse(sessionStorage.getItem("currentUser")) : {};
    let request = [{
      userHash: user ? user.UserHash : "",
      linkCategory: linksForm.form.value.linkCategory,
      linkName: linksForm.form.value.linkName,
      authorName: user ? user.FirstName + ' ' + user.LastName : "",
      publishedDate: new Date().toDateString(),
      publishedStatus: linksForm.form.value.publishedStatus3 ? "Yes" : "No",
      linkUrl: linksForm.form.value.linkUrl
    }]

    let url: string = "/web/register_new_link/recordNewLink";
    this.commonService.postrequestwithouttoken(url, request)
      .subscribe(() => {
        this.sbar.open("Successfuly Saved Link")
      }, () => {
        //error from api 
        this.sbar.open("Error in saving Link")
      });
      linksForm.reset();

  }
  


  getCategoryList() {
    // this.categoryList = new CategoryData(this.translate).getArticle();
    // console.log(this.categoryList);
    let url: string = "/categoryList";
    this.commonService.getrequestwithouttoken(url)
      .subscribe((data) => {
        this.categoryList = data.articleCategoryList;
      })
  };

  getLinksList() {
    // this.linksList = new CategoryData(this.translate).getLinks();
    // console.log(this.linksList);
    let url: string = "/categoryList";
    this.commonService.getrequestwithouttoken(url)
      .subscribe((data) => {
        this.linksList = data.linkCategoryList;
      })
  };

  fileChange(element) {
    this.uploadedFiles = element.target.files;
  }

  upload() {
    let formData = new FormData();
    for (var i = 0; i < this.uploadedFiles.length; i++) 
    {
      formData.append("uploads[]", this.uploadedFiles[i], this.uploadedFiles[i].name);
    }
    this.commonService.postrequestwithouttoken('/image_upload', formData)
      .subscribe((response) => {
        this.imageFile = response.imageName;
        this.article();
        console.log('response received is ', response);
      })
  }

  openEditModal(article: Article): void {
    const dialogRef = this.dialog.open(ArticleEditComponent, {
      data: article,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(article => {
      if (article) {
        if (article.articleTitle) {
          this.sbar.open(this.translate.instant("Successfully_saved_article"));
        }
        else {
          this.sbar.open(this.translate.instant("Error in saving article"));
        }
      }
    });
  }


  getArticleList(): void {
    let user = sessionStorage.getItem("currentUser") ? JSON.parse(sessionStorage.getItem("currentUser")) : {};
    let url: string = "/web/get_article_onuser/"+user.UserHash+"/queryArticleOnUser";
    this.commonService.getrequestwithouttoken(url)
      .subscribe((data) => {
        if (!(data === "error")) {
          this.verifiedBlobList = data;
        }
        for (var i = 0; i < this.verifiedBlobList.length; i++) {
          let result: Article = new Article();
          result.userHash = this.verifiedBlobList[i].Record.UserHash;
          result.articleImageName = this.verifiedBlobList[i].Record.ArticleImageName;
          result.authorName = this.verifiedBlobList[i].Record.AuthorName;
          result.publishedDate = this.verifiedBlobList[i].Record.PublishedDate;
          result.articleCategory = this.verifiedBlobList[i].Record.ArticleCategory;
          result.articleTitle = this.verifiedBlobList[i].Record.ArticleTitle;
          result.articleText = this.verifiedBlobList[i].Record.ArticleText;
          result.publishedStatus1 = this.verifiedBlobList[i].Record.PublishedStatus;
          result.articleHash = this.verifiedBlobList[i].Record.ArticleHash;
          this.finalList.push(result);
        }
      })
  }

}
