import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { WINDOW } from './window.provider';

@Injectable()
export class CommonService {

	constructor(private http: HttpClient, @Inject(WINDOW) private window: Window) { }

	postrequestwithouttoken(urlParams, data): Observable<any> {
		//var baseUrl = 'http://' + this.window.location.hostname + '/api';
		//var baseUrl = 'http://' + 'localhost' + ':' + '4000/api/v1';
		var baseUrl = 'https://bicmobile.redledger.eu/api/v1';
		var endPoint = baseUrl + urlParams;
		var promise = this.http.post(endPoint, data);
		return promise;
	};


	getrequestwithouttoken(urlParams): Observable<any> {
		//var baseUrl = 'http://' + this.window.location.hostname + '/api';
		//var baseUrl = 'http://' + 'localhost' + ':' + '4000/api/v1';
		var baseUrl = 'https://bicmobile.redledger.eu/api/v1';
		var endPoint = baseUrl + urlParams;
		var promise = this.http.get(endPoint);
		return promise.pipe(catchError(this.handleError));
		
	};

	private handleError(err: HttpErrorResponse) {
		console.log(err);
		return throwError(err.error.message);
	}

}