import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthService } from './auth.service';
import { SharedModule } from '../shared/shared.module'
import { AuthGuard } from './auth.guard';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild([
            { path: 'login', component: LoginComponent },
            { path: 'register', component: RegisterComponent }
        ])
    ],
    declarations: [
        LoginComponent,
        RegisterComponent
    ],
    providers: [
        AuthService,
        AuthGuard
    ]
})
export class UserModule { }