import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './homePage.component';
import { ArticleEditComponent } from './articleEdit.component';
import { SharedModule } from '../shared/shared.module'
import { HomeAuthGuard} from './home.auth.guard';
import { AngularEditorModule } from '@kolkov/angular-editor';


@NgModule({
    imports: [
        SharedModule,
        AngularEditorModule,
        RouterModule.forChild([
            { path: 'home', component: HomePageComponent,  canActivateChild: [HomeAuthGuard],  children: [
                { path: 'article', component: ArticleEditComponent }
            
            ] }
            
        ])      
    ],

    declarations: [
        HomePageComponent,
        ArticleEditComponent
        
    ],
    entryComponents: [
        ArticleEditComponent
    ],
    providers: [
        HomeAuthGuard
    ]
})
export class HomeModule { }