export class Article {
    articleCategory: string;
    articleTitle: string;
    articleText: string;
    userHash: string;
    hash: string;
    articleImageName: string;
    authorName: string;
    publishedDate: string;
    publishedStatus1: string;
    Date1:string;
    articleHash:string;
}