import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { User } from '../../Models/user';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource, MatSort, MatPaginator, MatSnackBar, MatDialog } from '@angular/material';

@Component({
  templateUrl: './register.component.html'
})

export class RegisterComponent {
  errorMessage: string = ""
  model = new User();
  
  constructor(private router: Router,
    private authService: AuthService,
    private sbar: MatSnackBar,
    private translate: TranslateService) { }


  //register new user

  register(registerForm: NgForm) {
    //check for validations 
    if (registerForm && registerForm.valid) {
      //let userName = registerForm.form.value.userName.trim();
      let email = registerForm.form.value.email.trim();
      let password = registerForm.form.value.password
      let confirmPassword = registerForm.form.value.confirmPassword

      let request = {
        // userName: userName,
        firstName: registerForm.form.value.firstName,
        lastName: registerForm.form.value.lastName,
        email: email,
        publicKey: password
      }

      // // if (userName.length < 3 || userName.length > 63 || !userName.match(/^[0-9a-z-]+$/) || userName.match(/^[-]/) || userName.match(/[-]+$/) || userName.match(/[A-Za-z0-9]+(--[A-Za-z0-9]+)/)) {
      // //   this.errorMessage = this.translate.instant("REGISTER.Username_Validation");
      // } 
      //  if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        if (!email.match(/^[\w.+\-]+@\w+([\.-]?)fujitsu\.com$/)) {
        this.errorMessage = this.translate.instant("REGISTER.Please_enter_valid_email");
      } else if (!password.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/)) {
        this.errorMessage = this.translate.instant("REGISTER.Password_Validation");
      } else if (password != confirmPassword){
        this.errorMessage = this.translate.instant("REGISTER.Password_do_not_match");
      } else{
        this.authService.register(request)
          .subscribe(() => {
            this.sbar.open(this.translate.instant("User Successfuly Registered"));
            //console.log("success");
            this.router.navigate(["/login"]);
          }, () => {
            //error from api 
            this.errorMessage =
              this.translate.instant("REGISTER.This_user_name/email_already_exists")
          });
      }
    }
  }
}
