import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  errorMessage: string = "";
  returnUrl: string;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.authService.logout();
    // get return url from route parameters or default to '/document'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
  }

  login(loginForm: NgForm): void {
    if (loginForm && loginForm.valid) {

      let request = {
        email: loginForm.form.value.email,
        publicKey: loginForm.form.value.password,
        org: "Org1"
      }

      this.authService.login(request)
        .subscribe(() => {
          console.log("success");
          this.authService.currentUser.email = request.email;
          this.router.navigate([this.returnUrl]);
        }, () => {
          this.errorMessage =
              this.translate.instant("Please enter correct email or password")
        }
        );
    }
  }
}