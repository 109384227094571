import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../Models/user';
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from '../Services/common.service';
import * as moment from "moment";

@Injectable()
export class AuthService {
    currentUser: User;
    redirectUrl: string;


    constructor(private http: HttpClient, private commonService: CommonService) {
        this.currentUser = new User();
    }

    register(request): Observable<any> {
        return this.commonService.postrequestwithouttoken("/enroll_Users", request);
    }


   
    login(request): Observable<any> {
        return this.commonService.getrequestwithouttoken("/authenticate_user/"+request.email+"/"+request.publicKey+"/"+request.org+"/queryUser")
        .pipe(map(authResult => {
            this.setSession(authResult);
            return authResult;
        }));
}    

    setSession(authResult: any): any {
        if (authResult) {
            if (authResult.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                sessionStorage.setItem('token', JSON.stringify(authResult.token));
                const expiresAt = moment().add(authResult.expiresIn, 'second');
                sessionStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
            }
            if (authResult.response) {
                sessionStorage.setItem('currentUser', JSON.stringify(authResult.response[0].Record))
            }
        }
    }

   
    logout(): void {
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("expires_at");
    }

    public isLoggedIn() {
        return moment().isBefore(this.getExpiration());
    }

    getExpiration() {
        const expiration = sessionStorage.getItem("expires_at");
        const expiresAt = JSON.parse(expiration);
        console.log(expiresAt);
        return moment(expiresAt);
    }
}