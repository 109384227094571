export class Glossary {
    category: string;
    glossaryTerm: string;
    glossaryDesc: string;
    glossaryAcronym: string;
    userHash: string;
    hash: string;
    authorName: string;
    publishedDate: string;
    Date1: string;
    publishedStatus2: string;
}