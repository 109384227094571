import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Bicweb';

  constructor(private translate: TranslateService) {
    translate.addLangs(["en", "fr", "ja", "it", "es", "nl", "pt", "sv", "de"]);

    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/de|fr|ja|it|es|nl|pt|sv|en/) ? browserLang : 'en');
    //translate.use("ja");
  }
}
