export class Links {
    linkCategory: string;
    hash: string;
    linkName: string;
    userHash: string;
    authorName: string;
    publishedDate: string;
    publishedStatus3: string;
    Date1: string;
    linkUrl: string;
}