import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { UserModule } from './User/user.module';
import { HomeModule } from './Home/home.module';
import { AppComponent } from './app.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { CommonService } from './Services/common.service';
import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MatPaginatorIntl } from '@angular/material';
import { PaginatorI18n } from './PaginatorI18n';
import { WINDOW_PROVIDERS } from './Services/window.provider';
import { JwtInterceptor } from './Services/jwt.interceptor';
import { RouterModule, Routes } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule, 
    AngularEditorModule,
    UserModule,
    HomeModule,
    AppRoutingModule,
    RouterModule.forRoot(routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [CommonService,
    WINDOW_PROVIDERS,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000, verticalPosition: 'top', horizontalPosition: 'right', panelClass: ['snackbar'] } },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: MatPaginatorIntl, useClass: PaginatorI18n
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
