import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class PaginatorI18n extends MatPaginatorIntl {

    constructor(private translate: TranslateService) {
        super();
        this.getPaginatorIntl();
    }

    getPaginatorIntl() {
        this.translate.get(['PAGINATOR.ITEMS_PER_PAGE_LABEL', 'PAGINATOR.NEXT_PAGE_LABEL', 'PAGINATOR.PREVIOUS_PAGE_LABEL', 'PAGINATOR.FIRST_PAGE_LABEL', 'PAGINATOR.LAST_PAGE_LABEL']).subscribe(translation => {
            this.itemsPerPageLabel = translation['PAGINATOR.ITEMS_PER_PAGE_LABEL'];
            this.nextPageLabel = translation['PAGINATOR.NEXT_PAGE_LABEL'];
            this.previousPageLabel = translation['PAGINATOR.PREVIOUS_PAGE_LABEL'];
            this.firstPageLabel = translation['PAGINATOR.FIRST_PAGE_LABEL'];
            this.lastPageLabel = translation['PAGINATOR.LAST_PAGE_LABEL'];
            this.changes.next();
        });
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return this.translate.instant('PAGINATOR.RANGE_PAGE_LABEL_1', { length });
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return this.translate.instant('PAGINATOR.RANGE_PAGE_LABEL_2', { startIndex: startIndex + 1, endIndex, length });
    }
}