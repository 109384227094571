import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from '../Services/common.service';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Article } from '../Models/article';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatTableDataSource, MatSort, MatPaginator, MatSnackBar, MatDialog } from '@angular/material';

@Component({
  templateUrl: './articleEdit.component.html'
})
export class ArticleEditComponent implements OnInit {
  model: Article = new Article();
  isNew: boolean = true;
  uploadedFiles: Array<File>;
  categoryList: any[];
  imageFile: string;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '5rem',
    maxHeight: '15rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    sanitize: false,
    toolbarPosition: 'top',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };

  constructor(
    private dialogRef: MatDialogRef<ArticleEditComponent>,
    private router: Router,
    private route: ActivatedRoute,
    private sbar: MatSnackBar,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private data,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getCategoryList()
    if (this.data) {
        this.model.articleTitle = this.data.articleTitle;
        this.model.articleCategory = this.data.articleCategory;
        this.model.articleText = this.data.articleText;
        this.model.publishedStatus1 = this.data.publishedStatus1;
        this.model.articleHash = this.data.articleHash;
        this.isNew = false;
      }
    }

    saveArticle() {
      let user = sessionStorage.getItem("currentUser") ? JSON.parse(sessionStorage.getItem("currentUser")) : {};
      let request = {
        userHash: user ? user.UserHash : "",
        articleImageName: this.uploadedFiles[0].name === undefined ? '' : this.uploadedFiles[0].name,
        //articleImageName : this.imageFile,
        authorName: user ? user.FirstName + ' ' + user.LastName : "",
        publishedDate: new Date().toDateString(),
        articleCategory: this.model.articleCategory,
        articleTitle: this.model.articleTitle,
        articleText: this.model.articleText,
        publishedStatus: this.model.publishedStatus1 ? "Yes" : "No",
        articleHash: this.model.articleHash,
      }
        let url: string =  "/web/update_article/updateArticle";
          this.commonService.postrequestwithouttoken(url, request)
            .subscribe(() => { 
            }, () => {
              this.sbar.open("Successfuly Saved Article")
              this.close();
            });
        }

      fileChange(element) {
        this.uploadedFiles = element.target.files;
      }
    
      upload() {
        let formData = new FormData();
        for (var i = 0; i < this.uploadedFiles.length; i++) 
        {
          formData.append("uploads[]", this.uploadedFiles[i], this.uploadedFiles[i].name);
        }
        this.commonService.postrequestwithouttoken('/image_upload', formData)
          .subscribe((response) => {
            this.imageFile = response.imageName;
            this.saveArticle();
            console.log('response received is ', response);
          })
      }

      close(): void {
        this.dialogRef.close();
      }

      getCategoryList() {
        // this.categoryList = new CategoryData(this.translate).getArticle();
        // console.log(this.categoryList);
    
        let url: string = "/categoryList";
        this.commonService.getrequestwithouttoken(url)
          .subscribe((data) => {
            this.categoryList = data.articleCategoryList;
          })
      };
    
  }

  
